import React from 'react'
import { graphql } from 'gatsby'
import createLink from '../../componentUtils/createLink.js'
// import { makeStyles } from '@mui/styles' //@TODO: check if okay with new material version
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import getLocalizedLiterals from '../../../jsonldUtils/getLocalizedLiterals.js'

console.warn('@TODO: see how to remplace deprecated @mui/style')
// const useStyles = makeStyles({
//   table: {
//     minWidth: 700,
//   },
//   propsFrom: {
//     'background-color': '#3f51b559'
//   }
// })


export const query = graphql`
fragment valueOfPropertiesComponent on Query {
  allRdfProperty(filter: {range: {elemMatch: {id: {eq: $id}}}}) {
    nodes {
      id
      label {
        _language
        _value
      }
      comment {
        _language
        _value
      }
      domain {
        id
        label {
          _language
          _value
        }
      }
    }
  }
}

fragment asSuperClasses on Query {
  rdfsClass(id: {eq: $id}) {
    id
    subClassOf {
      id
      label {
        _language
        _value
      }
    }
  }
}
`

// Exemple de test pour ce composant :
// avec plusieurs propriétés valorisantes : http://localhost:1111/nfr/Certification
//  cas special : http://localhost:1111/xsd/string
// sans propriété valorisante : http://localhost:1111/nfr/SiteNumerique
//   => cas particulier, c'est une des classes parent qui est une valeure ciblée
// @TODO: remonter ce point de modélisation : la propriété valorisante devrait indiquer la classe et ses sous-classes en target
export default function ObjectValueOfPropertiesComponent(props) {
  // const cssClasses = useStyles()
  const properties = props.data.allRdfProperty.nodes
  const klass = props.data.rdfsClass
  return (
    <>
      {/* {properties.length ? getValueOfTable(properties, klass, cssClasses) : emptyMessage(klass)} */}
      {properties.length ? getValueOfTable(properties, klass, undefined) : emptyMessage(klass)}
    </>
  )
}

const defaultClassLabel = '__no_label_defined_for_this_class__'
function emptyMessage(klass) {
  const kl = klass.label ? getLocalizedLiterals(klass.label)[0] : defaultClassLabel
  return (
    <>
      <p>Il n'existe pas de propriétés valorisant directement <b>{kl}</b>. </p>
      {klass.subClassOf ?
        <>
          Mais sans doute l'une des classes parentes suivantes:
          <ul>
            {klass.subClassOf.map(k => (<li>{createLink(k)}</li>))}
          </ul>
        </>
        : <>Il s'agit d'un cas particulier : soit cette classe n'est pas utilisée dans le modèle, soit il y a un bug dans ce composant.</>
      }
    </>
  )
}

function getValueOfTable(properties, klass, cssClasses) {
  const kl = klass.label ? getLocalizedLiterals(klass.label)[0] : defaultClassLabel
  return (
    <>
      <p>Les instances de <b>{kl}</b> peuvent être des valeurs des propriétés suivantes :</p>
      {/* @TODO: make a test of properties size */}
      <TableContainer component={Paper}>
        {/* <Table className={cssClasses.table} aria-label="spanning table"> */}
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>Propriété</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Classe(s) avec cette propriété</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {properties.map((property,i) => getPropertyRaw(property, i))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

function getPropertyRaw(property, index) {
  const comment = property.comment ? getLocalizedLiterals(property.comment)[0] : ''
  return (
    <TableRow key={`${property.id}-${index}`}>
      <TableCell>{createLink(property)}</TableCell>
      <TableCell>{comment}</TableCell>
      <TableCell>{createClasses(property.domain)}</TableCell>
    </TableRow>
  )
}

function createClasses(classes) {
  if (!classes) return
  return (
    <>
      {classes.map((klass, i) => {
        return (
          <React.Fragment key={i}>
            {createLink(klass)} <br />
          </React.Fragment>
        )
      })}
    </>
  )
}
