import React from 'react'
import { graphql } from "gatsby"
import Mermaid from "react-mermaid2"
import classDiagramBuilder from '../../../mermaidUtils/classDiagramBuilder.js'

// @TODO: review the query with the new graphql "virtual inverse properties"

export const query = graphql`
fragment classAndInheritance on Query {
  rdfsClass(id: {eq: $id}) {
    id
    isAbstract
    subClassOf {
      id
      isAbstract
      label {
        _language
        _value
      }
      hasProperties{
				id
        label {
          _language
          _value
        }
        range{
					id
          ... on rdfs_Class {
            label {
              _language
              _value
            }
          }
          ... on rdfs_Datatype {
            label {
              _language
              _value
            }
          }
        }
      }
    }
    hasProperties {
      id
      label {
        _language
        _value
      }
      range {
        id
        ... on rdfs_Class {
          label {
            _language
            _value
          }
        }
        ... on rdfs_Datatype {
          label {
            _language
            _value
          }
        }
      }
    }
    isRangedBy {
      id
      label {
        _language
        _value
      }
      domain {
        id
        label {
          _language
          _value
        }
      }
    }
  }
}
`

// test exemple : http://localhost:1111/nfr/Certification

export default function ObjectMermaidComponent(props) {

  // default for testing chart
  const chart = classDiagramBuilder(props.data)

  return (
    <Mermaid chart={chart}
    config = {{
      theme: "default"
    }}
    />
  )
}

// autres exemple de charts
// live editor : https://mermaid-js.github.io/mermaid-live-editor/
/*

# contextual test :
classDiagram
    Formation <|-- Module

    credits_ECTS --> Formation : relatif_a

    Module --> Module : se_compose_de
    class credits_ECTS{
        +int nombre_credits
    }


# basic test
graph TD;
        A-->B;
        A-->C;
        B-->D;
        C-->D;


# E-R
erDiagram
          CUSTOMER }|..|{ DELIVERY-ADDRESS : has
          CUSTOMER ||--o{ ORDER : places
          CUSTOMER ||--o{ INVOICE : "liable for"
          DELIVERY-ADDRESS ||--o{ ORDER : receives
          INVOICE ||--|{ ORDER : covers
          ORDER ||--|{ ORDER-ITEM : includes
          PRODUCT-CATEGORY ||--|{ PRODUCT : contains
          PRODUCT ||--o{ ORDER-ITEM : "ordered in"

# Class

classDiagram
    Animal <|-- Duck
    Animal <|-- Fish
    Animal <|-- Zebra
    Animal : +int age
    Animal : +String gender
    Animal: +isMammal()
    Animal: +mate()
    class Duck{
      +String beakColor
      +swim()
      +quack()
    }
    class Fish{
      -int sizeInFeet
      -canEat()
    }
    class Zebra{
      +bool is_wild
      +run()
    }




*/
