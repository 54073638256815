import React from 'react'
import { graphql } from 'gatsby'
// import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import createLink from '../../componentUtils/createLink.js'
import getLocalizedLiterals from '../../../jsonldUtils/getLocalizedLiterals.js'

console.warn('@TODO: see how to remplace deprecated @mui/style')
// @TODO: use sx instead
// const useStyles = makeStyles({
//   table: {
//     minWidth: 700,
//   },
//   propsFrom: {
//     'background-color': '#3f51b559'
//   }
// })

// @TODO: restart here to integrate and test

export const query = graphql`
fragment classFields on rdfs_Class {
  id
  label {
    _language
    _value
  }
  hasProperties {
    id
    label {
      _language
      _value
    }
    comment {
      _language
      _value
    }
    range {
			id
      ... on rdfs_Class {
        label {
          _language
          _value
        }
      }
      ... on rdfs_Datatype {
        label {
          _language
          _value
        }
      }
    }
  }
}

fragment classRecursive on rdfs_Class {
  ...classFields
  subClassOf {
    ...classFields
    subClassOf {
      ...classFields
      subClassOf {
        ...classFields
        subClassOf {
          ...classFields
          subClassOf {
            ...classFields
            subClassOf {
              ...classFields
            }
          }
        }
      }
    }
  }
}

fragment classDescription on Query{
	rdfsClass(id: {eq: $id}) {
      ...classRecursive
  }
}
`

// Exemple de test pour ce composant avec 3 niveaux d'héritage :
// http://localhost:1111/nfr/Module
// ==> pour que ce test fonctionne l'issue suivante doit être résolue : https://gitlab.com/mmorg/nodefr-2/-/issues/24

export default function ObjectPropertiesComponent(props) {
  // const cssClasses = useStyles()
  const klass = props.data.rdfsClass

  // @TODO: use position='stiky' for "property of" headers
  return (
    <>
      <TableContainer component={Paper}>
        {/* <Table className={cssClasses.table} aria-label="spanning table"> */}
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>Propriété</TableCell>
              <TableCell>Type(s) cible</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {classPropsRows(klass, cssClasses)}
            {recursiveProps(klass, cssClasses)} */}
            {classPropsRows(klass)}
            {recursiveProps(klass)}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )

}

function recursiveProps(klass, cssClasses) {
  if (!klass.subClassOf) return []
  // const r = klass.subClassOf.map(k => [classPropsRows(k, cssClasses), recursiveProps(k, cssClasses)])
  const r = klass.subClassOf.map(k => [classPropsRows(k), recursiveProps(k)])
  return r.flat()
}

function classPropsRows(klass, cssClasses) {
  return (
    <React.Fragment key={klass.id}>
      <TableRow>
        {/* <TableCell colSpan={3} className={cssClasses.propsFrom}>Propriétés de {createLink(klass)}</TableCell> */}
        <TableCell colSpan={3}>Propriétés de {createLink(klass)}</TableCell>
      </TableRow>
      {klass.hasProperties.map(getPropertyRaw)}
    </React.Fragment>
  )
}



function getPropertyRaw(property, index) {
  const comment = property.comment ? getLocalizedLiterals(property.comment)[0] : ''
  return (
    <TableRow key={`${property.id}-${index}`}>
      <TableCell>{createLink(property)}</TableCell>
      <TableCell>{createRanges(property.range)}</TableCell>
      <TableCell>{comment}</TableCell>
    </TableRow>
  )
}

function createRanges(ranges) {
  if (!ranges) return
  return (
    <>
      {ranges.map((klass, i) => {
        return (
          <React.Fragment key={i}>
            {createLink(klass)} <br />
          </React.Fragment>
        )
      })}
    </>
  )
}
