import React from 'react'
import { graphql } from 'gatsby'
import createLink from '../../componentUtils/createLink.js'


export const query = graphql`
fragment moreSpecificTypesComponent on Query {
  allRdfsClass(filter: {subClassOf: {elemMatch: {id: {eq: $id}}}}) {
    nodes {
      id
      label {
        _language
        _value
      }
    }
  }
}
`

// Exemple de test pour ce composant avec plusieurs sous-classes :
// avec des specifics : http://localhost:1111/nfr/EntiteDuMondeDeLaFormation
// sans specifics : http://localhost:1111/nfr/Certification

export default function ObjectMoreSpecificTypesComponent(props) {
  const klasses = props.data.allRdfsClass.nodes

  return (
    <>
      {klasses.length ?
        <ul>
          {klasses.map(k => (<li key={`mst-${k.id}`}>{createLink(k)}</li>))}
        </ul>
        : <p>Pas de classes plus spécifiques</p>
      }
    </>
  )

}
