// @TODO: mermaid don't accept class names with ":" in the text like `nfr:EntiteDuMondeDeLaFormation`
//    even quotes or escape don't work. Slash also don't work... so removing the ns
// @TODO: throw this problem in the mermaid's github
const getLibelle = (n) => n.id.split(':')[1]

export default function classDiagramBuilder(rdfsModel) {

    const { rdfsClass } = rdfsModel
    const { subClassOf, hasProperties: properties, isRangedBy: propertiesThatRange } = rdfsClass

    // 1/ parentClasses management 
    let parentClasses = []
    let inheritRelations = []
    if (subClassOf) {
        parentClasses = subClassOf.map(cl => {
            return `
class ${getLibelle(cl)}{
    ${cl.isAbstract ? '<<abstract>>' : ''}
    ${getPropertiesStrings(cl.hasProperties).join('\n    ')}
}
`
        })

        inheritRelations = subClassOf.map(cl => {
            return `${getLibelle(cl)} <|-- ${getLibelle(rdfsClass)}`
        })
    }

    // 2/ currentClass management
    const instructions = getPropertiesStrings(properties)

    const currentClass = `
class ${getLibelle(rdfsClass)}{
    ${rdfsClass.isAbstract ? '<<abstract>>' : ''}
    ${instructions.join('\n        ')}
}
`

    // 3/ Class that use the current one 
    const propertiesInstructions = propertiesThatRange.map(n => {

        return !n.domain? null : n.domain.map(nd => { // multiples domains assign to the property
            // we don't map multiples property's .range as it may be unclear in the diagram
            return `${getLibelle(nd)} --> ${getLibelle(rdfsClass)} : ${getLibelle(n)}`

        })

    }).flat()

    const diag = `
classDiagram

${parentClasses.join()}

${currentClass} 

%% inheritance relations 
${inheritRelations.join('\n')}

%% these are "ranged" classes, only display name
%% @TODO: make them clicable on React
%%   ==> test with the lib and see / update this 2 : 
%%   https://github.com/mermaid-js/mermaid/issues/1402
%%   https://github.com/mermaid-js/mermaid/issues/1808
${propertiesInstructions.join('\n')}

%% exemple pour les relations cycliques :
%% Module --> Module : se_compose_de
`

    return diag
}

function getPropertiesStrings(properties) {
    return properties.map(n => {
        if (!n.range) return []
        return n.range.map(nr => `+${nr.id} ${getLibelle(n)}`)
    }).flat()
}