import React, { useState } from 'react'
import { graphql } from 'gatsby'
import initDashTree from './dashutils/initDashTree.js'
import getCardNode from './dashutils/getCardNode.js'
import addCardNode from './dashutils/addCardNode.js'
import PageDataContext, { defaultDataContext } from './dashwood/PageDataContext'
import * as dashNodeComponents from './dashNodeComponents'
import EntityInfoComponent from './cardComponents/EntityInfoComponent'
import ListLinksComponent from './cardComponents/ListLinksComponent'
import OccupationInfoComponent from './cardComponents/OccupationInfoComponent'
import ClassInfoComponent from './cardComponents/ClassInfoComponent'
import ClassLiteralsComponent from './cardComponents/ClassLiteralsComponent'
import TodoComponent from './cardComponents/TodoComponent.js'
import ObjectMermaidComponent from './cardComponents/ObjectMermaidComponent.js'
import ObjectPropertiesComponent from './cardComponents/ObjectPropertiesComponent.js'
import ObjectMoreSpecificTypesComponent from './cardComponents/ObjectMoreSpecificTypesComponent.js'
import ObjectValueOfPropertiesComponent from './cardComponents/ObjectValueOfPropertiesComponent.js'
import ConceptSchemeLiteralsComponent from './cardComponents/ConceptSchemeLiteralsComponent.js'
import Seo from '../seo.js'
import DashHeart from '../dashibiden/DashHeart.js'
import getEntityLabel from '../../EntityUtils/getEntityLabel.js'


const components = {
  // generic dashTree properties
  ...dashNodeComponents,
  // generic components
  'EntityInfoComponent': EntityInfoComponent,
  'ListLinksComponent': ListLinksComponent,
  // for esco :
  'OccupationInfoComponent': OccupationInfoComponent,

  // utils
  'TodoComponent': TodoComponent,

  // for rdfs classes
  'ClassInfoComponent': ClassInfoComponent,
  'ClassLiteralsComponent': ClassLiteralsComponent,
  'ObjectMermaidComponent': ObjectMermaidComponent,
  'ObjectPropertiesComponent': ObjectPropertiesComponent,
  'ObjectMoreSpecificTypesComponent': ObjectMoreSpecificTypesComponent,
  'ObjectValueOfPropertiesComponent': ObjectValueOfPropertiesComponent,

  // for skos classes:
  'ConceptSchemeLiteralsComponent': ConceptSchemeLiteralsComponent

}

export const query = graphql`
query conceptSchemeQuery($id: String) {

  entity: skosConceptScheme(id: {eq: $id}) {
    id
    title {
      _language
      _value
    }
  }

}
`
// @TODO : review it with the more recent `DashTreeLayout7` structure
// @TODO : rename as skosConceptScheme
export default function DashLayoutConceptScheme(props) {

  // Gestion des contextes et des données partagées dans la page
  const [dataContext, setDataContext] = useState(defaultDataContext)
  const updateDataContext = (v) => setDataContext(v)

  const dashTree = initDashTree()

  // @TODO: see exactly what append here and make clean
  const { entity } = props.data
  const { pageData } = props.pageContext
  pageData.entity = entity

  const entityName = getEntityLabel(entity)
  const classLitCard = getCardNode(pageData, 'ConceptSchemeLiteralsComponent',
  { cardName: entityName, columnSize: 4, })
  addCardNode(dashTree, classLitCard, 0, 0)


  // const mermaid = getCardNode(pageData, 'ObjectMermaidComponent', { cardName: 'Représentation UML' })
  // addCardNode(dashTree, mermaid, 0, 1)

  // const objectProps = getCardNode(pageData, 'ObjectPropertiesComponent', { cardName: 'Propriétés de l\'objet' })
  // addCardNode(dashTree, objectProps, 1, 0)

  // const moreSpecific = getCardNode(pageData, 'ObjectMoreSpecificTypesComponent', { cardName: 'Classes plus spécifiques', columnSize: 4, })
  // addCardNode(dashTree, moreSpecific, 1, 1)

  // const valueOfProps = getCardNode(pageData, 'ObjectValueOfPropertiesComponent', { cardName: 'Propriétés ayant pour valeur une instance de cette classe'})
  // addCardNode(dashTree, valueOfProps, 2, 0)

  const todoExamples = { contentText: '@TODO:\n 1/ Ajout de la description \n 2/ ajout visualisation', ...pageData }
  const todoE = getCardNode(todoExamples, 'TodoComponent', { cardName: 'Améliorations', columnSize: 4, })
  addCardNode(dashTree, todoE, 2, 1)

  return (
    <PageDataContext.Provider value={{
      dataContext,
      updateDataContext,
    }}>
      <Seo title={entityName} />
      <DashHeart dashTree={dashTree} components={components} />
    </PageDataContext.Provider>
  );
}
