import React from 'react';


export default function ClassInfoComponent(props){
    const {data} = props
    let content = 'No description for this item'
    if(data) content = JSON.stringify(data)
    return (
        <React.Fragment>
            <p>{content}</p>
        </React.Fragment>
      );
}